.maskedInput {
  height: 37px;
  width: 100%;
  border-radius: 2px;

  .input {
    height: 100%;
    width: 100%;
    padding: 8px 16px;
    outline: none;
    background-color: transparent;
    border: 1px solid #cbd0dd !important;
    transition: all 0.25s ease-in-out;
    color: #31374a;
    border-radius: 8px;
    font-size: 12.8px;
    line-height: 1.49;
    font-weight: 600;
    &:disabled {
      height: 37px !important;
      background-color: #eef0f2;
      color: #9fa6bc;
    }
    &::placeholder {
      color: #9fa6bc;
    }

    &:focus-visible,
    &:active {
      border: 1px solid #8200bf !important;
      border-color: #8200bf;
      outline: 0;
      box-shadow:
        inset 0 1px 2px transparent,
        0 0 0 0.25rem rgba(56, 116, 255, 0.25);
      transition: all 0.25s ease-in-out;
    }
  }
}

.phoneInput {
  padding: 2px 16px;
  border: 1px solid #cbd0dd;
  height: 37px;
  width: 100%;
  border-radius: 6px;
  outline: none;
  font-size: 12.8px;
  line-height: 1.49;
  font-weight: 600;

  &:focus-within {
    border: 1px solid #8200bf !important;
    border-color: #8200bf;
    outline: 0;
    box-shadow:
      inset 0 1px 2px transparent,
      0 0 0 0.25rem rgba(56, 116, 255, 0.25);
    transition: all 0.25s ease-in-out;
  }

  input {
    border: none;
    outline: none;

    &::placeholder {
      color: #9fa6bc;
    }
  }
}

.errMsg {
  color: #f76659;
  font-size: 75%;
}
