.paper {
  margin-top: 5px;
  margin-right: 10px;
  filter: drop-shadow(0px 0px 1px rgba(26, 32, 36, 0.035))
    drop-shadow(0px 12px 24px rgba(91, 104, 113, 0.24));
}

.btn {
  margin-right: 10px;
  border: 1px solid rgb(203, 208, 221);
  border-radius: 4px;
  min-width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 0.2s;

  &:hover {
    border-color: #8200bf;
  }
}

@media (max-width: 768px) {
  .btn {
    margin-left: -32px;
    margin-right: 0px;
    border: 1px solid rgb(203, 208, 221);
    border-radius: 4px;
    min-width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 0.2s;

    &:hover {
      border-color: #8200bf;
    }
  }
}

.menu {
  .menuItem {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    box-shadow: inset 0px -1px 0px #e5e9eb;

    gap: 8px;
    background-color: #fff;
    cursor: pointer;

    &.active {
      color: #fff;
      background-color: #8200bf;

      &:hover {
        background: #8200bf;
      }
    }

    .dragIcon {
      cursor: grab;
    }

    .itemText {
      margin-bottom: 0;
      flex: 1;
      font-size: 14px;
    }

    &:hover {
      background: #f6f8f9;
    }
  }
}
